import { grid, layoutWrapper } from '@mixins';
import React, { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

import SectionTitle from '@components/SectionTitle';
import mq, { mqdown } from '@mq';

import LogosSvg from '@images/content/logos/logos.svg';

import ArrowSvg from '@vectors/arrow.svg';

const References = () => (
  <Container id="stories-referenzen">
    <SectionTitleContainer>
      <SectionTitle>Partner, Referenzen & Mieter</SectionTitle>
    </SectionTitleContainer>
    <SliderContainer>
      <Slider>
        <SliderInner>
          <img src={LogosSvg} alt="" />
          <img src={LogosSvg} alt="" />
        </SliderInner>
      </Slider>
    </SliderContainer>
  </Container>
);

const Container = styled.div`
  background-color: var(--color-alabaster);
  color: var(--color-black);

  padding-top: 3rem;
  padding-bottom: 3rem;

  ${mq.small} {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`;

const SectionTitleContainer = styled.div`
  ${grid};
`;

const SliderContainer = styled.div`
  /* ${layoutWrapper}; */
  position: relative;
  margin-top: 3.125rem;
  overflow: hidden;

  ${mq.small} {
    margin-top: 5rem;
  }
`;

const Slider = styled.div`
  grid-column: 1 / -1;
`;

const loop = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
      transform: translate3d(-100%, 0, 0);
    }
`;

const SliderInner = styled.div`
  display: flex;
  flex-wrap: nowrap;

  ${mq.small} {
  }

  img {
    height: 4rem;
    animation: ${loop} 110s linear infinite;

    ${mq.small} {
      height: 9rem;
      animation: ${loop} 120s linear infinite;
    }
  }
`;

export default References;
