import { grid } from '@mixins';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { scroll, animate } from 'motion';

import SectionTitle from '@components/SectionTitle';
import mq, { mqdown } from '@mq';
import Image from '@core/Image';

const About = () => {
  const {
    file: { childImageSharp: image },
  } = useStaticQuery(graphql`
    query AboutImageQuery {
      file(name: { eq: "about" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
            formats: [AUTO, WEBP, AVIF]
            layout: CONSTRAINED
            width: 900
            quality: 75
          )
        }
      }
    }
  `);

  const imageContainerRef = useRef(null);

  useEffect(() => {
    const animation = animate(imageContainerRef.current.querySelector('figure'), {
      scale: [1.1, 1],
    });
    scroll(animation, {
      target: imageContainerRef.current,
      offset: ['0 0.5', '1.5 0.5'],
    });
  }, []);

  return (
    <Container id="ueber-uns">
      <Text>
        <SectionTitle>Über Uns</SectionTitle>
        <MainText>
          Der Werterhalt und die Wertsteigerung von Immobilien stehen im Fokus unserer täglichen
          Arbeit. Wir begleiten dabei alle Prozesse von der Ankaufsprüfung und Objektübernahme bis
          hin zur Ertragsoptimierung und Steuerung von Verkaufsprozessen.
        </MainText>
        <MoreText>
          Wir stellen sicher, dass alle Immobilienziele durch ein strategisches und professionelles
          Assetmanagement erreicht werden. Unser Team von Immobilienspezialisten kennt jedes Gebäude
          im Detail und kümmert sich als kompetenter Ansprechpartner um die Wünsche der Mieter.
          Reaktives Propertymanagement war gestern, unser proaktives Managen garantiert heute und
          morgen den nachhaltigen Erfolg der Investitionen.
        </MoreText>
      </Text>
      <ImageContainer ref={imageContainerRef}>
        <Image image={image} />
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div`
  ${grid};
  background-color: var(--color-black-olive);
  color: var(--color-white);

  h2 {
    ${mq.small} {
      margin-bottom: 3.5rem;
      margin-left: calc(
        (100vw - (2 * var(--gutter)) - (11 * var(--grid-gap))) / 12 + var(--grid-gap)
      );
    }
  }
`;

const Text = styled.div`
  grid-column: 1 / -1;
  padding-top: 3rem;
  padding-bottom: 3rem;

  ${mq.small} {
    grid-column: 1 / 6;
  }

  ${mq.medium} {
    padding-bottom: 6rem;
    padding-top: 6rem;
  }
`;

const ImageContainer = styled.div`
  grid-column: 1 / -1;
  position: relative;
  overflow: hidden;

  ${mqdown.small} {
    padding-bottom: var(--gutter);
  }

  ${mq.small} {
    grid-column: 7 / -1;
    margin-right: calc(-1 * var(--gutter));
    border: 1.875rem solid var(--color-black-olive);
  }

  ${mq.medium} {
  }

  ${mq.large} {
    height: 100vh;

    figure {
      height: 100vh;
      width: 100%;

      > div {
        width: 100%;
      }

      img {
        height: 100vh;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
`;

const MainText = styled.div`
  font: var(--font-about-main);
  margin-top: 1.5rem;
  margin-bottom: 3.5rem;
  grid-column: 1 / -1;

  ${mq.small} {
    display: none;
  }
`;

const MoreText = styled.div`
  font: var(--font-about-more);

  ${mq.large} {
    /* margin-top: 4.5rem; */
  }
`;

const ImageCaption = styled.div`
  /* position: absolute; */
  top: 0;
  /* transform: translateY(-100%); */
  padding-bottom: 0.75rem;
  font: var(--font-about-caption);

  ${mq.small} {
    padding-bottom: 1rem;
  }
`;

export default About;
