import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// return to this eventually
// import Caption from '@core/Caption';
// {caption && <Caption text={image.description || caption} />}

const Image = ({ image, ...rest }) => (
  <figure {...rest}>
    <StyledGatsbyImage
      image={getImage(image.gatsbyImageData)}
      alt={image.description || image.altText || ''}
    />
  </figure>
);

const StyledGatsbyImage = styled(GatsbyImage)`
  height: 100%;
`;

export default Image;
