import { grid } from '@mixins';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { timeline, stagger, animate } from 'motion';

import mq from '@mq';

const TextSlide = ({ index, className, active, forward, text, inView }) => {
  const firstLine = useRef(null);
  const secondLine = useRef(null);
  const verticalLine = useRef(null);
  const lines = useRef(null);

  useEffect(() => {
    lines.current = [firstLine.current, secondLine.current];
  }, []);

  useEffect(() => {
    if (!active || !inView) {
      animate(lines.current, { scaleX: 0 }, { duration: 0.25 });
      animate(verticalLine.current, { opacity: 0 }, { duration: 0.25 });
    } else {
      timeline([
        [
          lines.current,
          {
            // transform: forward
            //   ? ['translate3d(200%,0,0)', 'translate3d(0,0,0)']
            //   : ['translate3d(-200%,0,0)', 'translate3d(0,0,0)'],
            scaleX: [0, 1],
            opacity: [0, 1],
            transformOrigin: forward ? 'left' : 'right',
          },
          { duration: 0.5, delay: stagger(0.25) },
        ],
        // [lines.current, { opacity: [0, 1] }, { duration: 2, at: 0 }],
        [verticalLine.current, { scaleY: [0, 1] }, { duration: 0.25, at: 0.4 }],
        [verticalLine.current, { opacity: [0, 1] }, { duration: 0.25, at: 0.4 }],
      ]);
    }
  }, [active, inView, forward]);

  return (
    <Container className={className}>
      <Line ref={firstLine} />
      <Index>
        <div>{index}</div>
        <VerticalLine ref={verticalLine} />
      </Index>
      <Text>{text}</Text>
      <Line ref={secondLine} />
    </Container>
  );
};

const Container = styled.div`
  ${grid};
  flex: 0 0 100%;
  align-content: space-around;
`;

const Line = styled.div`
  height: 0.0625rem;
  background-color: var(--color-black);
  grid-column: 1 / -1;
  /* opacity: 0; */

  ${mq.small} {
    grid-column: 2 / -2;
  }
`;

const Index = styled.div`
  grid-column: 1;
  font: var(--font-text-slider-index);
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;

  ${mq.small} {
    grid-column: 3;
    text-align: left;
    padding-top: 6.125rem;
    padding-bottom: 6.125rem;
  }
`;

const VerticalLine = styled.div`
  position: absolute;
  top: 3rem;
  bottom: 3rem;
  right: 0;
  width: 1px;
  background-color: var(--color-text);
  opactiy: 0;
  transform-origin: top;
`;

const Text = styled.div`
  grid-column: 2 / -1;
  font: var(--font-text-slider);
  padding-top: 3rem;
  padding-bottom: 3rem;

  ${mq.small} {
    grid-column: 4 / -2;
    padding-top: 6.125rem;
    padding-bottom: 6.125rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

export default TextSlide;
