import { grid } from '@mixins';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useEmblaCarousel from 'embla-carousel-react';
import { useInView } from 'react-intersection-observer';

import SectionTitle from '@components/SectionTitle';
import mq, { mqdown } from '@mq';

import ArrowSvg from '@vectors/arrow.svg';

import TextSlide from '@components/TextSlide';
import isMobile from '@utils/isMobile';

const slides = [
  'Unternehmerischer Weitblick für den Werterhalt und die WERTSTEIGERUNG von Immobilien',
  'ZIELERREICHUNG durch konstant professionelles Management',
  'SERVICEORIENTIERUNG gelebte 5-Sterne-DNA',
  'MIETERZUFRIEDENHEIT ist unser absoluter Fokus',
  'Laufende Steigerung der NETTOERTRÄGE durch konsequente NOI Optimierung',
  'Kontinuierliche Analyse und Optimierung der NACHHALTIGKEIT',
  'Einzigartiges NETZWERK öffnet Türen zu den Top - Entscheidern',
];

const lastSlideIndex = slides.length - 1;

const TextSlider = () => {
  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
    rootMargin: '-30% 0px -30% 0px',
    triggerOnce: true,
  });

  const [ref, embla] = useEmblaCarousel({ skipSnaps: false, loop: true, draggable: isMobile });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [forward, setForward] = useState(true);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrevious = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;

    const previous = embla.previousScrollSnap();
    const current = embla.selectedScrollSnap();

    if (current === 0 && previous === lastSlideIndex) {
      setForward(true);
    } else if (previous === 0 && current === lastSlideIndex) {
      setForward(false);
    } else if (current > previous) {
      setForward(true);
    } else if (current === previous) {
      setForward(true);
    } else {
      setForward(false);
    }

    setSelectedIndex(current);
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;

    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <Container id="wertversprechen">
      <SectionTitleContainer>
        <SectionTitle>Unsere sieben Wertversprechen</SectionTitle>
      </SectionTitleContainer>
      <SliderContainer ref={inViewRef}>
        <Slider ref={ref} className="embla">
          <SliderInner className="embla__container">
            {slides.map((slide, index) => (
              <TextSlide
                className="embla__slide"
                index={index + 1}
                active={selectedIndex === index}
                forward={forward}
                key={index}
                text={slide}
                inView={inView}
              />
            ))}
          </SliderInner>
        </Slider>
        <Previous onClick={scrollPrevious} />
        <Next onClick={scrollNext} />
        <Dots>
          {scrollSnaps.map((_, index) => (
            <Dot key={index} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
          ))}
        </Dots>
      </SliderContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: #dcdad2;
  color: var(--color-black);

  padding-top: 3rem;
  padding-bottom: 6rem;

  ${mq.small} {
    padding-top: 6rem;
    padding-bottom: 12rem;
  }
`;

const SectionTitleContainer = styled.div`
  ${grid};
`;

const SliderContainer = styled.div`
  position: relative;
  margin-top: 4.375rem;
  overflow: hidden;

  ${mq.small} {
    margin-top: 8rem;
  }
`;

const Slider = styled.div`
  grid-column: 1 / -1;

  ${mq.small} {
    grid-column: 2 / -1;
  }
`;
const SliderInner = styled.div`
  display: flex;
`;

const Navigation = styled(ArrowSvg)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  ${mqdown.small} {
    display: none;
  }
`;

const Previous = styled(Navigation)`
  left: 1.5rem;
  transform: translateY(-50%) rotate(180deg);
`;

const Next = styled(Navigation)`
  right: 1.5rem;
`;

const Dots = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  gap: 1rem;

  ${mq.small} {
    display: none;
  }
`;

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  border: 0.0625rem solid var(--color-black);
  transition: background-color var(--motion);
  background-color: ${props => props.selected && `var(--color-text)`};
`;

export default TextSlider;
