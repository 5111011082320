import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import About from '@components/About';
import Facts from '@components/Facts';
import TextSlider from '@components/TextSlider';
import References from '@components/References';
import Team from '@components/Team';
import Video from '@components/Video';
import Panorama from '@components/Panorama';

const HomePage = ({
  data: {
    contentfulHomepage: { slideshow, video, videoMobile },
  },
}) => (
  <Main>
    <Video video={video} videoMobile={videoMobile} />
    <About />
    <Facts />
    <TextSlider />
    <Panorama />
    <References slideshow={slideshow} />
    <Team />
  </Main>
);

const Main = styled.main``;

export const homepageQuery = graphql`
  query HomepageQuery {
    contentfulHomepage {
      video {
        url
      }
      videoMobile {
        url
      }
      slideshow {
        description {
          raw
        }
        title
        subtitle
        image {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
            formats: [AUTO, WEBP, AVIF]
            aspectRatio: 1.178
            resizingBehavior: FILL
            cropFocus: FACES
            quality: 75
          )
        }
      }
    }
  }
`;

export default HomePage;
