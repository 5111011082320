import { cover, layoutWrapper } from '@mixins';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import mq, { mqdown } from '@mq';
import ArrowDownSvg from '@vectors/arrow-down.svg';
import isBrowser from '@utils/isBrowser';

const Video = ({ video, videoMobile }) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScroll = useCallback(() => {
    setHasScrolled(true);
  }, []);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    window.addEventListener('scroll', handleScroll, { once: true });

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Container>
      <VideoElement src={video.url} muted autoPlay playsInline loop />
      <VideoMobileElement src={videoMobile.url} muted autoPlay playsInline loop />
      <Text>
        Der Werterhalt und die Wertsteigerung von Immobilien stehen im Fokus unserer täglichen
        Arbeit. Wir begleiten dabei alle Prozesse von der Ankaufsprüfung und Objektübernahme bis hin
        zur Ertragsoptimierung und Steuerung von Verkaufsprozessen.
      </Text>
      <Arrow visible={!hasScrolled}>
        <ArrowDownSvg />
      </Arrow>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-color: var(--color-black-olive);
`;

const VideoElement = styled.video`
  ${cover};
  object-fit: cover;

  ${mqdown.small} {
    display: none;
  }
`;

const VideoMobileElement = styled.video`
  ${cover};
  object-fit: cover;

  ${mq.small} {
    display: none;
  }
`;

const Text = styled.div`
  ${layoutWrapper};
  position: absolute;
  bottom: 0;
  width: 100%;
  color: var(--color-white);
  font: var(--font-video-text);
  padding-bottom: 6.25rem;

  ${mqdown.small} {
    display: none;
  }
`;

const arrowBounce = keyframes`
  0% {
    transform: translate3d(0,0,0) rotate(90deg);
  }
  
  10% {
    transform: translate3d(0,30%,0) rotate(90deg);
  }
  
  20% {
    transform: translate3d(0,0,0) rotate(90deg);
  }

  30% {
    transform: translate3d(0,30%,0) rotate(90deg);
  }
  
  40% {
    transform: translate3d(0,0,0) rotate(90deg);
  }
  
  100% {
    transform: translate3d(0,0,0) rotate(90deg);
  }
`;

const Arrow = styled.div`
  position: absolute;
  bottom: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity var(--motion-triple);

  ${mq.small} {
    bottom: 2.25rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(90deg);
    animation: ${arrowBounce};
    animation-duration: 4s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    ${mq.small} {
      width: 2rem;
      height: 2rem;
    }

    path {
      fill: var(--color-white);
    }
  }
`;

export default Video;
