import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { scroll, animate } from 'motion';

import CoreImage from '@core/Image';
import { mqdown } from '@mq';

const About = () => {
  const {
    file: { childImageSharp: image },
  } = useStaticQuery(graphql`
    query PanoramaImageQuery {
      file(name: { eq: "panorama" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
            quality: 75
          )
        }
      }
    }
  `);

  const imageContainerRef = useRef(null);

  useEffect(() => {
    scroll(animate(imageContainerRef.current.querySelector('figure'), { scale: [1.3, 1] }), {
      target: imageContainerRef.current,
      offset: ['0 1', '1 0'],
    });
  }, []);

  return (
    <Container ref={imageContainerRef}>
      <Image image={image} />
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
`;

const Image = styled(CoreImage)`
  height: 100vh;
  width: 100vw;

  img {
    object-fit: cover;
    height: 120vh;
    width: 120vw;

    ${mqdown.small} {
      object-position: 18%;
    }
  }
`;

export default About;
