import mq from '@mq';
import styled from 'styled-components';

const SectionTitle = styled.h2`
  font: var(--font-section-title);
  grid-column: 1 / -1;

  ${mq.small} {
    grid-column: 2 / -1;
  }
`;

export default SectionTitle;
