import { grid } from '@mixins';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useCountUp } from 'react-countup';
import { useInView } from 'react-intersection-observer';

import SectionTitle from '@components/SectionTitle';
import mq, { mqdown } from '@mq';

import AreaSvg from '@vectors/facts-area.svg';
import BuildingsSvg from '@vectors/facts-buildings.svg';
import CitiesSvg from '@vectors/facts-cities.svg';
import CountriesSvg from '@vectors/facts-countries.svg';
import PeopleSvg from '@vectors/facts-people.svg';
import UnitsSvg from '@vectors/facts-units.svg';

const Facts = () => (
  <Container id="facts-figures">
    <SectionTitle>Facts + Figures</SectionTitle>
    <FactList>
      <Fact name="Länder" end={2}>
        <Icon>
          <CountriesSvg />
        </Icon>
      </Fact>
      <Fact name="Städte" end={8}>
        <Icon>
          <CitiesSvg />
        </Icon>
      </Fact>
      <Fact name="Immobilien" end={40}>
        <Icon>
          <BuildingsSvg />
        </Icon>
      </Fact>
      <Fact name="Mieteinheiten" end={335} thirdLargest>
        <Icon>
          <UnitsSvg />
        </Icon>
      </Fact>
      <Fact name="Quadratmeter" end={165200} secondLargest>
        <Icon>
          <AreaSvg />
        </Icon>
      </Fact>
      <Fact
        name="Menschen besuchen, leben oder arbeiten in unseren Immobilien p. a."
        end={3500000}
        largest
      >
        <Icon>
          <PeopleSvg />
        </Icon>
      </Fact>
    </FactList>
  </Container>
);

const Fact = ({ children, name, end, ...rest }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '-5% 0px -5% 0px',
    triggerOnce: true,
  });

  const countUpRef = useRef(null);

  const { start, reset } = useCountUp({
    ref: countUpRef,
    separator: '.',
    startOnMount: false,
    duration: 1,
    start: 0,
    end,
  });

  useEffect(() => {
    if (inView) {
      start();
    }
  }, [inView, start]);

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <FactContainer ref={ref}>
      {children}
      <Number ref={countUpRef} {...rest} />
      <Name>{name}</Name>
    </FactContainer>
  );
};

const Container = styled.div`
  ${grid};
  background-color: var(--color-alabaster);
  color: var(--color-black);

  padding-top: 3rem;
  padding-bottom: 3rem;

  ${mq.small} {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  ${mq.medium} {
  }
`;

const FactList = styled.ul`
  grid-column: 1 / -1;
  display: grid;
  row-gap: 4.5rem;
  margin-top: 3rem;

  ${mq.small} {
    margin-top: 6rem;
    row-gap: 5.375rem;
  }

  ${mq.medium} {
    grid-template-columns: auto auto;
  }

  ${mq.large} {
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
`;

const FactContainer = styled.li`
  display: grid;
  row-gap: 1.5rem;
  grid-template-rows: 3.725rem auto;
  align-content: start;

  ${mqdown.small} {
    ${grid({ gutter: false })};
  }

  ${mq.small} {
    grid-template-columns: minmax(6.5625rem, auto) 1fr;
    grid-template-rows: 5.9rem auto;
    row-gap: 1.875rem;
    column-gap: 2.5rem;
  }

  ${mq.large} {
    row-gap: 1.25rem;
    column-gap: 1rem;
  }

  ${mq.huge} {
    row-gap: 1.875rem;
    column-gap: 2.5rem;
  }
`;

const Number = styled.div`
  font: var(--font-facts-number);
  position: relative;
  grid-column: 2 / -1;
  grid-row: 1;

  ${mq.small} {
    ${props =>
      props.largest &&
      css`
        min-width: calc(18.75rem + 2.5rem);
      `}

    ${props =>
      props.secondLargest &&
      css`
        min-width: calc(14.375rem + 2.5rem);
      `}

    ${props =>
      props.thirdLargest &&
      css`
        min-width: calc(6.875rem + 2.5rem);
      `}
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 3.125rem;
    height: 0.0625rem;
    background-color: var(--color-black);

    ${mq.small} {
      width: 4.375rem;
    }
  }
`;

const Name = styled.div`
  font: var(--font-facts-name);
  grid-column: 2 / -1;
  grid-row: 2;
`;

const Icon = styled.div`
  grid-column: 1;
  grid-row: 1;
  justify-self: end;
  justify-self: end;

  svg {
    height: 100%;
    width: auto;
  }
`;

export default Facts;
